import React, { useState, useEffect } from "react"
import { GlobalStyle } from "~components/Layout/GlobalStyle"
import { Link } from "gatsby"
import SEO from "~components/Layout/seo"
import Loadable from "@loadable/component"
import styled from "styled-components"
import { mobile } from "~utilities/mediaQuery"
import Icon from "~assets/icon"
import Button from "~components/Shared/Button"
import Background from "../components/sections/Header/Background"

const Globe = Loadable(() => import("../components/sections/Header/Globe"))

const NotFoundPage = () => {
  const [drift, setDrift] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDrift(true)
    }, 1500)
    return () => clearTimeout(timer)
  }, [drift])

  return (
    <div>
      <SEO title="404 NOT FOUND" />
      <GlobalStyle />
      <Section>
        <Content>
          <Icon icon="logo" width="105" style={{ marginBottom: "10px" }} />
          <h1>404! You are leaving our planet...</h1>
          <p>The page you're looking for is not found. Let's bring you home.</p>

          <Link to="/">
            <Button theme="red" content="Home" icon="globe" />
          </Link>
        </Content>
        <StyledGlobe className={drift && "drift"} />
        <StyledBackground />
      </Section>
    </div>
  )
}
export default NotFoundPage

const Section = styled.section`
  position: relative;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  ${mobile} {
    & .mobileContent {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 12px;
      left: 24px;
    }
  }
`

const Content = styled.div`
  width: 75%;
  max-width: 750px;
  margin: 0 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 16px;
    margin-bottom: 40px;
    font-family: "JetBrains Mono", sans-serif;
  }

  ${mobile} {
    margin: 0 24px;
  }
`

const StyledGlobe = styled(Globe)`
  background-image: none !important;
  transition: transform 60s;
  will-change: transform;

  &.drift {
    transform: scale(0.1) translate(50%, -50%);
  }
`

const StyledBackground = styled(Background)`
  .radial {
    display: none;
  }
`
